<template>
  <div>
    <el-breadcrumb class="xiaxia" separator="/">
      <el-breadcrumb-item>账户设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flexCol alignItems">
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item label="头像：" prop="avatar">
            <el-upload
                action
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
            >
              <div class="upload-box">
                <div class="upload-list">
                  <i class="el-icon-mobile-phone"></i>
                  <span>上传头像</span>
                </div>
                <div class="upload-img">
                  <img v-if="ruleForm.avatar" :src="ruleForm.avatar">
                  <i v-else class="el-icon-picture"></i>
                </div>
              </div>
            </el-upload>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="姓名：">{{ ruleForm.nickName }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别：">
                <span>{{ ruleForm.gender === 0 ? '未知' : ruleForm.gender === 1 ? '男' : '女' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账户：">{{ ruleForm.loginName }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证号：">{{ ruleForm.idCardNo }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年龄：">{{ ruleForm.age }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号：">{{ ruleForm.phone }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="紧急联系人：">{{ ruleForm.emergencyContact }}</el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="紧急联系方式：">{{ ruleForm.emergencyPhone }}</el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button class="button-pwd" type="primary" @click="handlePwd">密码修改</el-button>
      </div>

      <el-dialog title="修改密码" class="updata-box" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%"
                 :before-close="handleClose">
        <el-form :model="upData" :rules="upDataRules" ref="upDataRulesForm" label-width="100px;" label-position="left">
          <el-form-item label="原密码：" prop="oldPwd" v-if="false">
            <el-input type="password" v-model="upData.oldPwd"></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPwd">
            <el-input type="password" v-model="upData.newPwd"></el-input>
          </el-form-item>
          <el-form-item label="确定密码：" prop="confirmPassword">
            <el-input type="password" v-model="upData.confirmPassword"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleEditPwd">完 成</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    const validatePassWord = (rule, value, callback) => {
      // let reg = /(?=^.{6,18}$)(?=.*\d)(?=.*\W+)(?=.*[a-zA-Z])(?!.*\s).*$/;
      let reg = /^\w{3,18}$/;
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else if (!reg.test(value)) {
        // callback(new Error("密码必须包含字母,数字和特殊字符,长度6-18位"));
        callback(new Error("长度为3-18位"));
      } else {
        callback();
      }
    };
    const validateNewPassWord = (rule, value, callback) => {
      // let reg = /(?=^.{6,18}$)(?=.*\d)(?=.*\W+)(?=.*[a-zA-Z])(?!.*\s).*$/;
      let reg = /^\w{3,18}$/;
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (!reg.test(value)) {
        // callback(new Error("密码必须包含字母,数字和特殊字符,长度6-18位"));
        callback(new Error("长度为3-18位"));
      } else {
        if (this.upData.confirmPassword !== "") {
          // 对第二个密码框单独验证
          this.$refs.ruleForm.validateField('confirmPassword');
        }
        callback();
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("确认密码不能为空"));
      } else if (value !== this.upData.newPwd) {
        callback(new Error("和设置的密码不一致"));
      } else {
        callback();
      }
    }
    return {
      ruleForm: {},
      rules: {},
      upData: {
        oldPwd: '',
        newPwd: '',
        confirmPassword: '',
      },
      upDataRules: {
        oldPwd: [
          {required: true, validator: validatePassWord, trigger: "blur"},
        ],
        newPwd: [
          {required: true, validator: validateNewPassWord, trigger: "blur"},
        ],
        confirmPassword: [
          {required: true, validator: validateConfirmPassword, trigger: "blur"},
        ],
      },

      dialogVisible: false,
      formDataReady: true,
      fileList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 获取当前登录的用户信息
    getData() {
      let that = this;
      that.$api.getloginInfo().then((res) => {
        if (res && res.data.code == 200) {
          that.ruleForm = res.data.data
        }
      })
    },

    fnUploadRequest(e) {
      this.utils.upFile(e.file).then((res) => {
        if (res && res.res.status == 200) {
          this.ruleForm.avatar = res.url;
          this.$api.updAvatar({
            id: this.ruleForm.id,
            avatar: res.url
          }).then((res) => {
            if (res && res.data.code == 200) {
              this.ruleForm = res.data.data
            }
          })
        }
      });

    },
    // 初始化表单
    handleInitForm() {
      this.formDataReady = false;
      this.$nextTick(() => {
        this.formDataReady = true;
      });
    },
    handlePwd() {
      this.dialogVisible = true;

    },
    handleClose() {
      this.upData = {
        oldPwd: '',
        newPwd: '',
        confirmPassword: '',
      };
      this.dialogVisible = false;
      this.handleInitForm();
    },
    handleEditPwd() {
      let _that = this;
      _that.$refs['upDataRulesForm'].validate((valid) => {
        if (valid) {
          // _that.dialogVisible = false;
          // let data = {
          //   id: _that.ruleForm.id,
          //   oldPwd: _that.upData.oldPwd,
          //   newPwd: _that.upData.newPwd,
          // };
          // _that.$api.updPwd(data).then((res) => {
          //   if (res.status == 200) {
          //     _that.handleCommand();
          //   } else {
          //     _that.utils.error(res.data.message);
          //   }
          // });
          let data = {
            id: _that.ruleForm.id,
            reqPassword: _that.upData.newPwd
          };
          _that.$api.updStaffPwd(data).then((res) => {
            if (res.status == 200) {
              _that.handleCommand();
            } else {
              _that.utils.error(res.data.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleCommand() {
      let _that = this;
      _that.$api.logout().then((res) => {
        if (res.data.code == 200) {
          _that.$router.push({
            path: "/",
          });
          localStorage.clear();
          _that.utils.success("修改成功，请重新登录");
        } else {
          _that.utils.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItems {
  align-items: center;
}


.button-pwd {
  width: 300px;
}

.content {
  width: 95%;
  background: #fff;
  padding: 30px;

  .el-input {
    width: 200px;
  }

  .el-select {
    width: 200px;
  }
}

.updata-box {
  .el-form-item {
    width: 100%;
  }
}

.upload-box {
  display: flex;

  .upload-list {
    width: 75px;
    height: 75px;
    border: 1px solid rgb(214, 214, 214);
    background-color: rgb(242, 242, 242);
    text-align: center;

    i {
      font-size: 25px;
      color: rgb(214, 214, 214);

      &:hover {
        color: rgb(100, 188, 156);
      }
    }

    span {
      color: rgb(165, 165, 165);
      display: block;

      &:hover {
        color: rgb(100, 188, 156);
      }
    }

    &:hover {
      background-color: white;
      border-color: rgb(100, 188, 156);
      cursor: pointer;
    }

  }

  .upload-img {
    width: 75px;
    height: 75px;
    border: 1px solid rgb(214, 214, 214);
    background-color: rgb(214, 214, 214);
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

    i {
      font-size: 35px;
      color: white;
    }
  }
}

.xiaxia {
  padding: 0 40px;
}

.demo-ruleForm {
  /deep/ .el-form-item {
    width: 100%;
    margin-bottom: 5px;
  }

  /deep/ .el-form-item__label {
    width: 120px;
  }
}
</style>
